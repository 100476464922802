<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px" :rules="rules">
				<el-row>
					<el-col :span="12">
						<el-form-item label="用户账号：" prop="accountNo">
							<el-input v-model="formData.accountNo"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="手机：" prop="mobile">
							<el-input v-model="formData.mobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="姓名：" prop="name">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="密码：">
							<el-input v-model="formData.pwd"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="邮箱：">
							<el-input v-model="formData.email"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="性别：">
							<el-radio-group v-model="formData.sex">
								<el-radio :label="0">保密</el-radio>
								<el-radio :label="1">男</el-radio>
								<el-radio :label="2">女</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="角色权限：">
							<el-select v-model="formData.roleIds" multiple collapse-tags clearable
								:popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所属部门：">
							<el-cascader v-model="formData.deptId" clearable :show-all-levels="false"
								:props="{ value: 'id', label: 'name', children: 'child',checkStrictly: true,emitPath:false }"
								:options="departments">
							</el-cascader>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="images">
					<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload"
						:http-request="Upload">
						<el-image v-if="headUrl" :src="headUrl" fit="fill">
						</el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="账号状态：" v-if="dialogObj.type!=0" prop="status">
							<el-switch v-model="formData.status" active-color="#4A99F6" :active-value="1"
								:inactive-value="0">
							</el-switch>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
				newFile: new FormData(),
				headUrl: "",
				roles: [],
				departments: [],
				rules: {
					name: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					status: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					accountNo: [{
						required: true,
						message: "此项必填",
						trigger: 'blur'
					}, {
						validator: (rule, value, callback) => {
							if (value) {
								let obj = {};
								if (this.dialogObj.type == 1) {
									obj = {
										accountNo: this.formData.accountNo,
										id: this.dialogObj.id
									}
								} else {
									obj = {
										accountNo: this.formData.accountNo
									}
								}
								this.$get("/user-api/user-base/validate/account", obj).then((
									res) => {
									if (res.code == 1000) {
										//成功
										if (res.data.result == 0) {
											callback(new Error(res.data.msg));
										} else {
											callback();
										}
									} else {
										this.$message.error(res.msg);
									}
								});
							} else {
								callback(new Error('请输入用户账号'))
							}
						},
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: "此项必填",
						trigger: 'blur'
					}, {
						validator: (rule, value, callback) => {
							console.log(value);
							if (value) {
								const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
								setTimeout(() => {
									if (!Number.isInteger(+value)) {
										callback(new Error('请输入数字'))
									} else {
										if (phoneReg.test(value)) {
											let obj = {};
											if (this.dialogObj.type == 1) {
												obj = {
													mobile: this.formData.mobile,
													id: this.dialogObj.id
												}
											} else {
												obj = {
													mobile: this.formData.mobile
												}
											}
											this.$get("/user-api/user-base/validate/mobile", obj).then(
												(res) => {
													if (res.code == 1000) {
														//成功
														if (res.data.result == 0) {
															callback(new Error(res.data.msg));
														} else {
															callback();
														}
													} else {
														this.$message.error(res.msg);
													}
												});
										} else {
											callback(new Error('电话号码格式不正确'))
										}
									}
								}, 100)
							} else {
								callback(new Error('请输入手机'))
							}
						},
						trigger: 'blur'
					}]
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.headUrl = "";
				this.departments = [];
				this.roles = [];
				this.getRoles();
				this.getDepartments();
				this.get();
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
			},
			//获取账户角色
			getRoles() {
				this.$get("/user-api/plat/role/list", null).then((res) => {
					if (res.code == 1000) {
						this.roles = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//获取部门
			getDepartments() {
				this.$get("/user-api/plat/dept/tree", null).then((res) => {
					if (res.code == 1000) {
						this.departments = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//获取数据
			get() {
				if (this.dialogObj.type == 1) {
					this.title = "编辑"
					this.$get("/user-api/plat/user/child/get", {
						id: this.dialogObj.id
					}).then((res) => {
						if (res.code == 1000) {
							this.formData = res.data;
							this.formData.pwd = "";
							//图片回显
							if (this.formData.head) {
								this.headUrl = configApi.photoURL + this.formData.head;
							}
							//账号权限回显
							this.formData.roleIds = [];
							for (let item of this.formData.roles) {
								this.formData.roleIds.push(item.id);
							}
						} else {
							this.$message.error(res.msg);
						}
					});
				} else {
					this.title = "新增员工";
				}
			},
			//上传前校验
			BeforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 10;
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png') {
					this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
				} else if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				} else {
					this.currentFileName = file.name;
					if (file) {
						if(this.newFile.has("file")){
							this.newFile.delete("file");
						}
						this.newFile.append('file', file);
						return true;
					} else {
						return false;
					}
				}
				return false;
			},
			//手动上传
			Upload(param) {
				this.loading = true;
				this.$upload(this.newFile).then((res) => {
					if (res.code == 1000) {
						this.formData.head = res.data
						this.headUrl = configApi.photoURL + res.data;
						this.loading = false;
						this.$message.success(res.msg);
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				}).catch(error => {
					this.$message.error(error);
				});
			},
			//提交表单
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						if (!this.formData.roleIds || this.formData.roleIds.length == 0) {
							this.$message.error("请选择角色权限");
							return;
						}
						this.loading = true;
						this.$postData("/user-api/plat/user/child/save", this.formData).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}

		::v-deep.el-image {
			width: 80px;
			height: 80px;
			margin-right: 20px;
		}

		.images {
			margin: 10px 25px;
		}
	}

	.avatar-uploader {
		display: inline-block;
		margin-right: 20px;

		::v-deep .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 80px;
			height: 80px;
		}

		::v-deep .el-upload:hover {
			border-color: #409EFF;
		}
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}
</style>
